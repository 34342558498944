import React from "react";
import "./src/global.css";
// gatsby-browser.js

export const onClientEntry = () => {
  const gtagScript = document.createElement("script");
  gtagScript.async = true;
  gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=G-XJTTHDR9T4";
  document.head.appendChild(gtagScript);

  const gtagInlineScript = document.createElement("script");
  gtagInlineScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-XJTTHDR9T4');
  `;
  document.head.appendChild(gtagInlineScript);
};
